import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import {
    faHistory,
    faInfoCircle,
    faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
    Nav,
    Navbar as NavbarComponent,
    NavDropdown,
    Container,
} from 'react-bootstrap'
import { useQuery } from 'react-query'
import { Link, NavLink } from 'react-router-dom'

import { editionApi } from '../apis'
import {
    AUTHORS_PATH,
    DOCUMENTS_PATH,
    generateEditionPath,
    HOME_PAGE_PATH,
    SCHEDULE_PATH,
} from '../paths'

import LoadingWrapper from './LoadingWrapper'
import styles from './Navbar.module.scss'

const Navbar = () => {
    const editionsQuery = useQuery('editions.all', () =>
        editionApi.queryEditionContents({ $orderby: 'data/uri/iv desc' })
    )

    return (
        <NavbarComponent className={styles.navbar} expand="md" variant="light">
            <Container>
                <NavbarComponent.Brand as={Link} to={HOME_PAGE_PATH}>
                    FotoBlázinec
                </NavbarComponent.Brand>
                <NavbarComponent.Toggle aria-controls="responsive-navbar-nav" />
                <NavbarComponent.Collapse>
                    <Nav className="ml-auto">
                        <Nav.Link as={NavLink} to={SCHEDULE_PATH}>
                            <FontAwesomeIcon icon={faCalendar} /> Program
                        </Nav.Link>
                        <Nav.Link as={NavLink} to={AUTHORS_PATH}>
                            <FontAwesomeIcon icon={faUsers} /> Autoři 2023
                        </Nav.Link>
                        <Nav.Link as={NavLink} to={DOCUMENTS_PATH}>
                            <FontAwesomeIcon icon={faInfoCircle} /> Pro
                            účastníky
                        </Nav.Link>
                        <NavDropdown
                            id="nav-previous-editions"
                            title={
                                <>
                                    <FontAwesomeIcon icon={faHistory} /> Minulé
                                    ročníky
                                </>
                            }
                        >
                            <LoadingWrapper query={editionsQuery}>
                                {(editionsResult) => (
                                    <>
                                        {editionsResult.items
                                            .slice(1)
                                            .map((edition) => (
                                                <NavDropdown.Item
                                                    key={edition.id}
                                                    as={Link}
                                                    to={generateEditionPath(
                                                        edition.data.uri.iv!
                                                    )}
                                                >
                                                    {edition.data.title.iv}
                                                </NavDropdown.Item>
                                            ))}
                                    </>
                                )}
                            </LoadingWrapper>
                        </NavDropdown>
                    </Nav>
                </NavbarComponent.Collapse>
            </Container>
        </NavbarComponent>
    )
}

export default Navbar
