import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useQuery } from 'react-query'

import { contactApi } from '../apis'
import mkcLogo from '../images/mkc.png'
import { fetchSingle } from '../utils'

import styles from './Footer.module.scss'
import LoadingWrapper from './LoadingWrapper'

const Footer = () => {
    const contactQuery = useQuery(
        'pages.contact',
        fetchSingle(() => contactApi.queryContactContents({}))
    )

    return (
        <footer className={styles.footer}>
            <Container>
                <Row>
                    <Col>
                        <h3>Kontakt</h3>
                        <LoadingWrapper query={contactQuery}>
                            {(contactResponse) => (
                                <ul className="list-unstyled">
                                    <li>
                                        <a
                                            href={`mailto:${contactResponse.data.email.iv}`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                            />{' '}
                                            {contactResponse.data.email.iv}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={
                                                contactResponse.data
                                                    .facebookLink.iv!
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faFacebook}
                                            />{' '}
                                            fotoblazinec.berounsky
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </LoadingWrapper>
                    </Col>
                    <Col className={styles.rightColumn}>
                        <p>Fotoblázinec se koná s podporou MKC Beroun.</p>
                        <img alt="Logo MKC Beroun" src={mkcLogo} />
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
